import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { TextField } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useDispatch, useSelector } from 'react-redux';

import './styles.css';
import { getVesselPDF, sendVesselAsMail } from "../../services";
import { getUserProfile } from '../../redux/selector';
import SendMailPopUp from "./sendMail";

const boxProps = {
    sx: {
        marginBottom: '20px',
        padding: '20px 0',
        borderRadius: '6px',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.33)',
        width: '100%'
    }
};
const titleStyles = {
    textAlign: 'left',
    marginLeft: '20px',
    fontSize: '18px',
    fontWeight: 'bold'
};

const shrinkProps = { InputLabelProps: { shrink: true } };
const stackProps = {
    spacing: { xs: 1, sm: 2, md: 3 },
    sx: {
        margin: '20px'
    },
    direction: "row",
    useFlexGap: true,
    flexWrap: "wrap"
};

const VesselUpdate = ({ setShowVendor }) => {

    const profileData = useSelector(getUserProfile);
    const [dsr, setDsr] = useState([
        { date: '', report: '' }
    ]);
    const mockPortCall = [
        {
            "berthDetails": {
                "berth": "fsf",
                "position": "sfsf",
                "maxDraftBerth": "sfs",
                "gangway": "fsfs",
                "maxDraftChannel": "sfs",
                "avgLoad": "sfs",
                "transitTime": "fsf",
                "stores": "fsf",
                "freshwater": "sfsf",
                "bunkers": "sfs",
                "hoseSize": "fsf",
                "notes": "sfsf"
            },
            "service": [
                {
                    "operation": "sf",
                    "parcel": "sfs",
                    "grade": "fsf",
                    "charterer": "sfsf",
                    "quantity": "",
                    "option": "sfs",
                    "stowage": "fsf",
                    "surveyor": "sfs",
                    "tankInspection": "fs"
                }
            ]
        },
        {
            "berthDetails": {
                "berth": "fsf",
                "position": "fs",
                "maxDraftBerth": "fsf",
                "gangway": "sfsf",
                "maxDraftChannel": "sf",
                "avgLoad": "sfs",
                "transitTime": "s",
                "stores": "",
                "freshwater": "",
                "bunkers": "sfs",
                "hoseSize": "",
                "notes": "sfs"
            },
            "service": [
                {
                    "operation": "sf",
                    "parcel": "sf",
                    "grade": "sfs",
                    "charterer": "s",
                    "quantity": "234",
                    "option": "sf",
                    "stowage": "sf",
                    "surveyor": "",
                    "tankInspection": ""
                },
                {
                    "operation": "cdgdg",
                    "parcel": "dg",
                    "grade": "dg",
                    "charterer": "fghj",
                    "quantity": "",
                    "option": "",
                    "stowage": "",
                    "surveyor": "",
                    "tankInspection": ""
                }
            ]
        }
    ];
    const [open, setOpen] = React.useState(false);
    const [vesselDetails, setvesselDetails] = useState({});
    const [schedule, setschedule] = useState([]);
    const [departure, setdeparture] = useState('');
    const [similarShow, setsimilarShow] = useState(false);
    const [berthDD, setberthDD] = useState('');
    const [portCall, setportCall] = useState([]);

    useEffect(()=>{
        // getBerthDetails();
    });

    const handleBerthDD = (e) => {
        setberthDD(e.target.value);
    };
    const handlesimilarClose = () => {
        setsimilarShow(false);
        setberthDD('');
    };

    const handleAddSchedule = () => {
        setschedule([...schedule, { berth: '', status: '', berthed: '', shift: '', eta: '', etd: '' }])
    };
    const handleDeleteSchedule = (index) => {
        return () => {
            const updatedVal = [...schedule];
            updatedVal.splice(index, 1);
            setschedule(updatedVal)
        }
    };
    const onChangeSchedule = (value, fieldName, index) => {
        const modifiedVal = [...schedule];
        modifiedVal[index] = { ...modifiedVal[index], [fieldName]: value }
        setschedule(modifiedVal);
    };

    const handleAddServ = (portIndex) => {
        return () => {
            const updatedVal = JSON.parse(JSON.stringify(portCall));
            updatedVal[portIndex]?.service?.push({
                operation: '', parcel: '', grade: '', charterer: '', quantity: '', option: '', stowage: '', surveyor: '', tankInspection: ''
            });
            setportCall(updatedVal)
        }
    };
    const onChangeServ = (value, fieldName, index, portIndex) => {
        const modifiedVal = [...portCall];
        modifiedVal[portIndex].service[index] = { ...modifiedVal[portIndex]?.service[index], [fieldName]: value }
        setportCall(modifiedVal);

    };
    const handleDeleteServ = (index, portIndex) => {
        return () => {
            const updatedVal = JSON.parse(JSON.stringify(portCall));
            updatedVal[portIndex]?.service?.splice(index, 1);
            setportCall(updatedVal)
        }
    };

    const handleAddBerth = () => {
        setportCall([...portCall, {
            "berthDetails": {
                "berth": "",
                "position": "",
                "maxDraftBerth": "",
                "gangway": "",
                "maxDraftChannel": "",
                "avgLoad": "",
                "transitTime": "",
                "stores": "",
                "freshwater": "",
                "bunkers": "",
                "hoseSize": "",
                "notes": ""
            },
            "service": []
        }])
    };
    const setberthDetails = (value, fieldName, index) => {
        const modifiedVal = [...portCall];
        modifiedVal[index].berthDetails = { ...modifiedVal[index]?.berthDetails, [fieldName]: value }
        setportCall(modifiedVal);
    };
    const handleDeleteBerth = (index) => {
        return () => {
            const updatedVal = JSON.parse(JSON.stringify(portCall));
            updatedVal?.splice(index, 1);
            setportCall(updatedVal)
        }
    };

    return <div className="vendor-wrapper">
        <h3>Vessel Details</h3>
        <div className="vendor-field-wrapper">
            <TextField value={vesselDetails.vessel} label="Vessel"
                onChange={(event) => { setvesselDetails({ ...vesselDetails, vessel: event.target.value }) }}
            />
            <TextField value={vesselDetails.voyage} label="Voyage"
                onChange={(event) => { setvesselDetails({ ...vesselDetails, voyage: event.target.value }) }}
            />
            <TextField value={vesselDetails.ports} label="Ports"
                onChange={(event) => { setvesselDetails({ ...vesselDetails, ports: event.target.value }) }}
            />
            <TextField value={vesselDetails.operator} label="Operator"
                onChange={(event) => { setvesselDetails({ ...vesselDetails, operator: event.target.value }) }}
            />
            <TextField value={vesselDetails.eta} label="ETA Anchorage"
                onChange={(event) => { setvesselDetails({ ...vesselDetails, eta: event.target.value }) }}
            />
            <TextField value={vesselDetails.appNo} label="Appointment #"
                onChange={(event) => { setvesselDetails({ ...vesselDetails, appNo: event.target.value }) }}
            />
            <TextField value={vesselDetails.billRef} label="Billing Reference"
                onChange={(event) => { setvesselDetails({ ...vesselDetails, billRef: event.target.value }) }}
            />
            <TextField value={vesselDetails.phone} label="Assigned Phone"
                onChange={(event) => { setvesselDetails({ ...vesselDetails, phone: event.target.value }) }}
            />
            <div className="textarea-wrapper">
                <h4>Vessel Notes</h4>
                <textarea value={vesselDetails.vesselNotes} label="Vessel Notes"
                    onChange={(event) => {
                        setvesselDetails({ ...vesselDetails, vesselNotes: event.target.value })
                    }}
                />
            </div>
            <div className="textarea-wrapper">
                <h4>Tender Notes</h4>
                <textarea value={vesselDetails.tender} label="Tender Notes"
                    onChange={(event) => {
                        setvesselDetails({ ...vesselDetails, tender: event.target.value })
                    }}
                />
            </div>
        </div>
        <div className="vendor-field-wrapper schedule-wrapper" >
            <Box {...boxProps}>
                <Grid
                    justify="space-between"
                    container
                    direction="row"
                    spacing={2}
                >
                    <Grid item xs={6}>
                        <div
                            className="box-title"
                            style={titleStyles}
                        >Tentative Schedule</div>
                    </Grid>
                    <Grid item xs={6} justifyContent="flex-end">
                        <Button
                            variant="contained"
                            onClick={handleAddSchedule}
                            sx={{
                                float: 'right',
                                marginRight: '20px'
                            }}
                        >
                            {`+ Add `}
                        </Button>
                    </Grid>
                </Grid>
                {schedule.map((item, index) => <Stack {...stackProps}>
                    <TextField type="text" {...shrinkProps} value={item?.berth} label="Berth"
                        onChange={(event) => {
                            onChangeSchedule(event.target.value, 'berth', index);
                        }}
                    />
                    <TextField type="text" {...shrinkProps} value={item?.status} label="Status"
                        onChange={(event) => {
                            onChangeSchedule(event.target.value, 'status', index);
                        }}
                    />
                    <TextField type="number" {...shrinkProps} value={item?.berthed} label="Berthed"
                        onChange={(event) => {
                            onChangeSchedule(event.target.value, 'berthed', index);
                        }}
                    />
                    <TextField type="number" {...shrinkProps} value={item?.shift} label="Shift"
                        onChange={(event) => {
                            onChangeSchedule(event.target.value, 'shift', index);
                        }}
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            key={`${index}-eta`}
                            defaultValue={dayjs(item?.eta)}
                            onChange={(newValue) => {
                                const dateFormatedVal = dayjs(newValue).format('YYYY-MM-DD h:mm:ss');
                                onChangeSchedule(dateFormatedVal, 'eta', index)
                            }}
                            format='DD-MM-YYYY h:mm:ss'
                            label="ETA"
                            slotProps={{
                                textField: {
                                    error: false,
                                },
                            }}
                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            key={`${index}-etd`}
                            defaultValue={dayjs(item?.etd)}
                            onChange={(newValue) => {
                                const dateFormatedVal = dayjs(newValue).format('YYYY-MM-DD h:mm:ss');
                                onChangeSchedule(dateFormatedVal, 'etd', index)
                            }}
                            format='DD-MM-YYYY h:mm:ss'
                            label="ETD"
                            slotProps={{
                                textField: {
                                    error: false,
                                },
                            }}
                        />
                    </LocalizationProvider>
                    <DeleteIcon sx={{
                        color: 'red',
                        marginTop: '15px',
                        cursor: 'pointer',
                        marginLeft: '20px',
                        marginBottom: '20px'
                    }}
                        onClick={handleDeleteSchedule(index)}
                    />
                </Stack>)}
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    key='departure'
                    defaultValue={dayjs(departure)}
                    onChange={(newValue) => {
                        const dateFormatedVal = dayjs(newValue).format('YYYY-MM-DD h:mm:ss');
                        setdeparture(dateFormatedVal)
                    }}
                    format='DD-MM-YYYY h:mm:ss'
                    label="Departure"
                    slotProps={{
                        textField: {
                            error: false,
                        },
                    }}
                />
            </LocalizationProvider>
        </div>

        <Box {...boxProps}>
            <Grid
                justify="space-between"
                container
                direction="row"
                spacing={2}
            >
                <Grid item xs={6}>
                    <div
                        className="box-title"
                        style={titleStyles}
                    >Berth Details</div>
                </Grid>
                <Grid item xs={6} justifyContent="flex-end">
                    <Button
                        variant="contained"
                        onClick={() => { setsimilarShow(true); }}
                        sx={{
                            float: 'right',
                            marginRight: '20px'
                        }}
                    >
                        {`+ Add Similar`}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleAddBerth}
                        sx={{
                            float: 'right',
                            marginRight: '20px'
                        }}
                    >
                        {`+ Add `}
                    </Button>
                </Grid>
            </Grid>
            {portCall.map(({ berthDetails, service }, portIndex) => <div className="port-call-wrapper">
                <Stack {...stackProps}>
                    <TextField value={berthDetails.berth} label="Berth"
                        onChange={(event) => { setberthDetails(event.target.value, 'berth', portIndex) }}
                    />
                    <TextField value={berthDetails.position} label="Position"
                        onChange={(event) => { setberthDetails(event.target.value, 'position', portIndex) }}
                    />
                    <TextField value={berthDetails.maxDraftBerth} label="Max Draft (Berth)"
                        onChange={(event) => { setberthDetails(event.target.value, 'maxDraftBerth', portIndex) }}
                    />
                    <TextField value={berthDetails.gangway} label="Gangway"
                        onChange={(event) => { setberthDetails(event.target.value, 'gangway', portIndex) }}
                    />
                    <TextField value={berthDetails.transitTime} label="Transit Time (From POB)"
                        onChange={(event) => { setberthDetails(event.target.value, 'transitTime', portIndex) }}
                    />
                    <TextField value={berthDetails.avgLoad} label="Average Load Rate"
                        onChange={(event) => { setberthDetails(event.target.value, 'avgLoad', portIndex) }}
                    />
                    <TextField value={berthDetails.heading} label="Heading"
                        onChange={(event) => { setberthDetails(event.target.value, 'berth', portIndex) }}
                    />
                    <TextField value={berthDetails.maxDraftChannel} label="Max Draft (Channel)"
                        onChange={(event) => { setberthDetails(event.target.value, 'maxDraftChannel', portIndex) }}
                    />
                    <TextField value={berthDetails.stores} label="Stores Allowed"
                        onChange={(event) => { setberthDetails(event.target.value, 'stores', portIndex) }}
                    />
                    <TextField value={berthDetails.freshwater} label="Freshwater Available"
                        onChange={(event) => { setberthDetails(event.target.value, 'freshwater', portIndex) }}
                    />
                    <TextField value={berthDetails.bunkers} label="Bunkers Allowed"
                        onChange={(event) => { setberthDetails(event.target.value, 'bunkers', portIndex) }}
                    />
                    <TextField value={berthDetails.hoseSize} label="Hose Size (Cargo/Vapor)"
                        onChange={(event) => { setberthDetails(event.target.value, 'hoseSize', portIndex) }}
                    />
                    <div className="textarea-wrapper">
                        <h4>Notes</h4>
                        <textarea value={berthDetails.notes} label="Notes"
                            onChange={(event) => {
                                setberthDetails(event.target.value, 'notes', portIndex)
                            }}
                        />
                    </div>
                    <DeleteIcon sx={{
                        color: 'red',
                        marginTop: '15px',
                        cursor: 'pointer',
                        marginLeft: '20px',
                        marginBottom: '20px'
                    }}
                        onClick={handleDeleteBerth(portIndex)}
                    />
                </Stack>
                <Box {...boxProps} sx={{
                    ...boxProps.sx,
                    margin: '0px 20px',
                    width: 'calc(100% - 40px)'
                }} >
                    <Grid
                        justify="space-between"
                        container
                        direction="row"
                        spacing={2}
                    >
                        <Grid item xs={6}>
                            <div
                                className="box-title"
                                style={titleStyles}
                            >Services</div>
                        </Grid>
                        <Grid item xs={6} justifyContent="flex-end">
                            <Button
                                variant="contained"
                                onClick={handleAddServ(portIndex)}
                                sx={{
                                    float: 'right',
                                    marginRight: '20px'
                                }}
                            >
                                {`+ Add `}
                            </Button>
                        </Grid>
                    </Grid>
                    {service.map((item, index) => <Stack className="service-wrapper" {...stackProps}>
                        <TextField type="text" {...shrinkProps} value={item?.operation} label="Operation"
                            onChange={(event) => {
                                onChangeServ(event.target.value, 'operation', index, portIndex);
                            }}
                        />
                        <TextField type="text" {...shrinkProps} value={item?.parcel} label="Parcel #"
                            onChange={(event) => {
                                onChangeServ(event.target.value, 'parcel', index, portIndex);
                            }}
                        />
                        <TextField type="text" {...shrinkProps} value={item?.grade} label="Grade"
                            onChange={(event) => {
                                onChangeServ(event.target.value, 'grade', index, portIndex);
                            }}
                        />
                        <TextField type="text" {...shrinkProps} value={item?.charterer} label="Charterer"
                            onChange={(event) => {
                                onChangeServ(event.target.value, 'charterer', index, portIndex);
                            }}
                        />
                        <TextField type="number" {...shrinkProps} value={item?.quantity} label="Quantity"
                            onChange={(event) => {
                                onChangeServ(event.target.value, 'quantity', index, portIndex);
                            }}
                        />
                        <TextField type="text" {...shrinkProps} value={item?.option} label="Option"
                            onChange={(event) => {
                                onChangeServ(event.target.value, 'option', index, portIndex);
                            }}
                        />
                        <TextField type="text" {...shrinkProps} value={item?.stowage} label="Stowage"
                            onChange={(event) => {
                                onChangeServ(event.target.value, 'stowage', index, portIndex);
                            }}
                        />
                        <TextField type="text" {...shrinkProps} value={item?.surveyor} label="Surveyor"
                            onChange={(event) => {
                                onChangeServ(event.target.value, 'surveyor', index, portIndex);
                            }}
                        />
                        <TextField type="text" {...shrinkProps} value={item?.tankInspection} label="Tank Inspection"
                            onChange={(event) => {
                                onChangeServ(event.target.value, 'tankInspection', index, portIndex);
                            }}
                        />
                        <DeleteIcon sx={{
                            color: 'red',
                            marginTop: '15px',
                            cursor: 'pointer',
                            marginLeft: '20px',
                            marginBottom: '20px'
                        }}
                            onClick={handleDeleteServ(index, portIndex)}
                        />
                    </Stack>)}
                </Box>
            </div>)}
        </Box>

        <button className="btn" style={{
            height: '40px',
            background: '#1976d2',
            marginTop: '10px'
        }} onClick={() => {
            const data = {
                vesselDetails,
                schedule,
                departure,
                portCall,
                add_by: profileData?.id || 1
            };
            console.log(data);
            getVesselPDF(data);
        }}>Generate PDF
        </button>

        <button className="btn" style={{
            height: '40px',
            background: '#1976d2',
            marginTop: '10px',
            marginLeft: '20px'
        }} onClick={() => {
            setOpen(true);
        }}>Send Mail
        </button>
        {open && <SendMailPopUp open={open} setOpen={setOpen} onSend={(mails) => {
            const data = {
                vesselDetails,
                schedule,
                departure,
                portCall,
                mails,
                add_by: profileData?.id || 1
            };
            console.log(data);
            sendVesselAsMail(data);
        }} />}
        {similarShow &&
            <Dialog
                open={similarShow}
                maxWidth='md'
                fullWidth
                onClose={handlesimilarClose}
            >
                <DialogTitle>Select Berth</DialogTitle>
                <DialogContent sx={{ padding: '20px !important' }}>
                    <FormControl sx={{ minWidth: '200px' }}>
                        <InputLabel id="demo-simple-select-label">Berth</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={berthDD}
                            label="Age"
                            onChange={handleBerthDD}
                        >
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                        </Select>
                    </FormControl>

                    {berthDD && <table className="berthTable" width="800">
                        <tbody>
                            <tr>
                                <td align="left" valign="top"
                                >Berth </td>
                                <td align="left" valign="top">LBC #1</td>
                                <td align="left" valign="top"
                                >Heading </td>
                                <td align="left" valign="top"> Masters Option</td>
                            </tr>
                            <tr>
                                <td align="left" valign="top"
                                >Position </td>
                                <td align="left" valign="top"><a

                                    href="http://maps.google.com/maps?z=12&amp;q=loc:29.608556,-95.022806"
                                    target="_blank"
                                    data-saferedirecturl="https://www.google.com/url?q=http://maps.google.com/maps?z%3D12%26q%3Dloc:29.608556,-95.022806&amp;source=gmail&amp;ust=1722512907475000&amp;usg=AOvVaw3AV-F3RUsTRfqLNCGVbAK9">29.608556,-95.022806</a>
                                </td>
                                <td align="left" valign="top"
                                >Max Draft (Channel)
                                </td>
                                <td align="left" valign="top">40 ft</td>
                            </tr>
                            <tr>
                                <td align="left" valign="top"
                                >Max Draft (Berth)
                                </td>
                                <td align="left" valign="top">40 ft</td>
                                <td align="left" valign="top"
                                >Stores Allowed</td>
                                <td align="left" valign="top">Hand Carry Only</td>
                            </tr>
                            <tr>
                                <td align="left" valign="top"
                                >Gangway </td>
                                <td align="left" valign="top">Vessel</td>
                                <td align="left" valign="top"
                                > Freshwater
                                    Available</td>
                                <td align="left" valign="top">No</td>
                            </tr>
                            <tr>
                                <td align="left" valign="top"
                                >Transit Time (From
                                    POB)</td>
                                <td align="left" valign="top">3 Hrs</td>
                                <td align="left" valign="top"
                                >Bunkers Allowed
                                </td>
                                <td align="left" valign="top">No</td>
                            </tr>
                            <tr>
                                <td align="left" valign="top"
                                >Average Load Rate
                                </td>
                                <td align="left" valign="top"></td>
                                <td align="left" valign="top"
                                >Hose Size
                                    (Cargo/Vapor)</td>
                                <td align="left" valign="top">6" Cargo</td>
                            </tr>
                            <tr >
                                <td align="left" valign="top"
                                >Notes: </td>
                                <td align="left" valign="top" colspan="3"> </td>
                            </tr>
                        </tbody>
                    </table>}
                </DialogContent>

                <DialogActions>
                    <Button onClick={handlesimilarClose}>Cancel</Button>
                    <Button type="submit" onClick={() => {
                        if (berthDD) {
                            setportCall([...portCall, {
                                "berthDetails": {
                                    "berth": "",
                                    "position": "",
                                    "maxDraftBerth": "",
                                    "gangway": "",
                                    "maxDraftChannel": "",
                                    "avgLoad": "",
                                    "transitTime": "",
                                    "stores": "",
                                    "freshwater": "",
                                    "bunkers": "",
                                    "hoseSize": "",
                                    "notes": ""
                                },
                                "service": []
                            }]);
                        }
                        handlesimilarClose();
                    }}>Add Berth</Button>
                </DialogActions>
            </Dialog>}
    </div>;
};

export default VesselUpdate;
