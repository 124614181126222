import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import Chip from '@mui/material/Chip';

import formData from '../../formData';
import FormBuilder from '../formBuilder/formBuilder';
import { dataMapper } from '../../utils';
import { baseUrl } from "../../constants";import {
  getDDActivityCall,
  getDDActUnitCall,
  getDDbankCall,
  getDDJobNumCall,
  getDDCliAddrCall,
  getDDPdaCall,
  getDDAgencyRoleCall
} from "../../redux/actions/dropDown";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
export default function ViewModal({
  open,
  setOpen,
  menuName = 'jobs',
  isEdit,
  isAdd = true,
  closeCb = () => { },
  payloadVal = {},
  dValue = {},
  title,
  isView,
  viewName
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  const [defaultFieldValue, setDefaultValue] = useState(dValue);
  const [defaultPdaValue, setDefaultPdaValue] = useState(dValue);
  const [defaultDsrValue, setDefaultDsrValue] = useState(dValue);
  // const [isAdd, setAdd] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  const handleClose = () => {
    closeCb();
    setOpen(false);
  };

  const fetchList = (url, payload, cb) => {
    fetch(url, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("NETWORK RESPONSE ERROR");
        }
      })
      .then(data => {
        cb(data);
      })
      .catch((error) => console.error("FETCH ERROR:", error));
  };

  const callFetchList = (reqPayload = {}) => {
    const { url, payload = {} } = formData[menuName]?.apiDetails?.list || {};
    if (url && !isEdit) {
      fetchList(
        url,
        { ...payload, ...reqPayload, ...payloadVal },
        (data) => {
          const dataMap = dataMapper(data.data)?.[0] || {};
          setDefaultValue({
            ...defaultFieldValue,
            ...dataMap,
            jobNum: {
              id: dValue.id,
              name: dValue.jobNum
            },
            pda: dValue?.pda?.[0],
            appointNum: {
              id: dValue.app_no_id,
              name: dValue.app_no
            }
          });
          setShowLoader(false);
        }
      );
      fetchList(
        formData['jobs']?.apiDetails?.list?.url,
        { ...payload, ...reqPayload, ...payloadVal },
        (data) => {
          const dataMap = dataMapper(data.data)?.[0] || {};
          setDefaultValue({
            ...defaultFieldValue,
            ...dataMap,
            jobNum: {
              id: dValue.id,
              name: dValue.jobNum
            },
            pda: dValue?.pda?.[0],
            appointNum: {
              id: dValue.app_no_id,
              name: dValue.app_no
            }
          });
          setShowLoader(false);
        }
      );
      fetchList(
        formData['pda']?.apiDetails?.list?.url,
        { ...formData['pda']?.apiDetails?.list?.payload, ...reqPayload, ...payloadVal },
        (data) => {
          const dataMap = dataMapper(data.data)?.[0] || {};
          setDefaultPdaValue({
            ...defaultPdaValue,
            ...dataMap,
            jobNum: {
              id: dValue.id,
              name: dValue.jobNum
            },
            pda: dValue?.pda?.[0],
            appointNum: {
              id: dValue.app_no_id,
              name: dValue.app_no
            }
          });
          setShowLoader(false);
        }
      );
      fetchList(
        formData['dsr']?.apiDetails?.list?.url,
        { ...formData['dsr']?.apiDetails?.list?.payload, ...reqPayload, ...payloadVal },
        (data) => {
          const dataMap = dataMapper([data.data[0]])?.[0] || {};
          setDefaultDsrValue({
            ...defaultDsrValue,
            ...dataMap,
            jobNum: {
              id: dValue.id,
              name: dValue.jobNum
            },
            pda: dValue?.pda?.[0],
            appointNum: {
              id: dValue.app_no_id,
              name: dValue.app_no
            }
          });
          setShowLoader(false);
        }
      );
    }
    else {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (open) {
      setShowLoader(true);
      setDefaultValue({
        ...defaultFieldValue,
        appointment: {
          id: dValue.app_no_id,
          name: dValue.app_no
        }
      });
      formData[menuName === 'pda' ? 'jobs' : menuName]?.apiDetails?.startUp?.(dispatch);
      if( isView || menuName === 'pda' ){
        [getDDActivityCall,
          getDDActUnitCall,
          getDDbankCall,
          getDDJobNumCall,
          getDDPdaCall,
          getDDAgencyRoleCall,
          getDDCliAddrCall].forEach((func) => {
            dispatch(func());
        })
      }
      callFetchList();
      if (menuName === 'dsr' || menuName === 'appointments') {
        fetchList(
          `${baseUrl}/api/service/appointment/list`,
          { app_id: dValue.app_no_id },
          (data) => {
            const dataMap = dataMapper(data.data)?.[0] || {};
            setDefaultValue({
              ...defaultFieldValue,
              ...dataMap,
              appNo: {id: dValue.app_no_id, name: dValue.app_no},
              reqNum: {id: dValue.id, name: dValue.jobNum},
              agency: dValue.agency
            });
          }
        );
      }
      else if (menuName === 'pda') {
        if( viewName === 'revisePDA' ){
          fetchList(
            `${baseUrl}/api/service/pda/list`,
            { job_id: dValue.id, "sort": "DESC" },
            (data) => {
              const dataMap = dataMapper(data.data)?.[0] || {};
              setDefaultValue({
                jobNum: dValue.id,
                ...defaultFieldValue,
                ...dataMap
              });
            }
          );
        }
          fetchList(
            `${baseUrl}/api/service/job/list`,
            { job_id: dValue.id },
            (data) => {
              const dataMap = dataMapper(data.data)?.[0] || {};
              setDefaultValue({
                jobNum: dValue.id,
                ...defaultFieldValue,
                ...dataMap
              });
            }
          );
      }
      else if (menuName === 'clientReviews') {
        setTimeout(() => {
          setDefaultValue({
            pdaNum: dValue.pda?.[0]?.id,
            id: dValue.pda?.[0]?.id,
            ...defaultFieldValue
          });
        }, 500)
      }
    }
  }, [open]);

  const resetForm = () => {
    // setDefaultValue({});
    handleClose();
    callFetchList();
  };

  const getTitle = () => {
    return title || <>
      {`${dValue.ship} @ ${dValue.port} `}
      <Chip
        label={dValue.jobNum}
        variant="outlined"
        size="small"
        sx={{
          backgroundColor: 'white',
          paddingTop: '1px',
          marginBottom: '1px'
        }}
      />
      {` ETA: ${dValue.eta}`}
    </>;
  }

  return (<>
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
    >
      <AppBar sx={{
        position: 'relative',
        backgroundColor: 'rgb(103,36,240)',
        textTransform: 'uppercase'
      }}>
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {getTitle()}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      {isView ? <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Job" {...a11yProps(0)} />
            <Tab label="PDA" {...a11yProps(1)} />
            <Tab label="DSR" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <FormBuilder
          key={'jobs'}
          defaultFieldValue={defaultFieldValue}
          isEdit={isEdit}
          resetForm={resetForm}
          isAdd={isAdd}
          menuName={'jobs'}
          callFetchList={callFetchList} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <FormBuilder
          key={'pda'}
          defaultFieldValue={defaultPdaValue}
          isEdit={isEdit}
          resetForm={resetForm}
          isAdd={isAdd}
          menuName={'pda'}
          callFetchList={callFetchList} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <FormBuilder
          key={'dsr'}
          defaultFieldValue={defaultDsrValue}
          isEdit={isEdit}
          resetForm={resetForm}
          isAdd={isAdd}
          menuName={'dsr'}
          callFetchList={callFetchList} />
        </CustomTabPanel>
      </Box> :
      <FormBuilder
        key={menuName}
        defaultFieldValue={defaultFieldValue}
        isEdit={isEdit}
        resetForm={resetForm}
        isAdd={isAdd}
        menuName={menuName}
        callFetchList={callFetchList} />}
    </Dialog>
    <Backdrop
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0.2)", color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={showLoader}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  </>
  );
}